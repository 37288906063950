<template>
  <v-dialog v-if="dialog" v-model="dialog" :key="id" max-width="80%" height="80%">
    <v-card class="pa-4" color="white" outlined>
      <v-row>
        <v-col cols="12" md="auto">
          <v-text-field v-model="nombre" label="Nombre" :readonly="!opt1" prepend-inner-icon="mdi-account-hard-hat"
            required />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field v-model="username" label="Nombre usuario" :readonly="!opt1" prepend-inner-icon="mdi-account"
            required />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field v-model="email" label="Correo" :rules="[rules.email]" :readonly="!opt1"
            prepend-inner-icon="mdi-email-outline" required />
        </v-col>
        <!-- <v-col cols="12" md="3">
          <v-select v-model="planta" :items="plantas" :readonly="!opt1" multiple
            prepend-inner-icon="mdi-office-building-marker-outline" label="Planta" />
        </v-col> -->
      </v-row>
      <v-row align="center">
        <v-col cols="12" md="auto">
          <v-select v-model="perfil" :items="perfiles" :readonly="perfedit" multiple
            prepend-inner-icon="mdi-account-lock-outline" label="Tipo Perfil" />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field v-model="contraseña" prepend-inner-icon="mdi-key-variant" :readonly="perfedit"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" label="Contraseña"
            @click:append="show1 = !show1" />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field v-model="contraseña1" prepend-inner-icon="mdi-key-variant" :readonly="perfedit"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
            label="Repetir contraseña" @click:append="show1 = !show1" />
        </v-col>
        </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!opt1" v-bind="attrs" v-on="on" plain @click="btn_edit()">editar datos</v-btn>
          </template>
          <span>Permite editar el perfil y la contraseña, si requiere cambiar el correo, debe eliminar este usuario y crear uno nuevo</span>
        </v-tooltip>
        
        <v-btn v-if="!opt1" :disabled="perfedit" plain @click="btn_del()">eliminar usuario</v-btn>
        <v-btn plain @click="btn_act()">{{btn_text}}</v-btn>
        <v-btn plain @click="dialog = false">salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AdminDataService from "../../services/AdminDataService";
export default {
 name: "clasif",
 components: {
  },
  data: () => ({
      id:'',
      id__:'',
      dialog:false,
      show1:false,
      opt1: true,
      optedit:true,
      plantas:[
        {text:"Paico", value:"paico"},
        {text:"Ochagavía", value:"ochagavia"},
        {text:"Arica", value:"arica"},
        {text:"Tecnologia", value:"montina"},
        {text:"Fábrica", value:"fabrica"}
        ],
      perfiles:[
        {text:"Basico", value:"usuario"},
        {text:"Administrador", value:"supervisor"}
        ],
      nombre:undefined,
      username:undefined,
      perfil:[],
      email:undefined,
      contraseña:undefined,
      contraseña1:undefined,
      planta:[],
      rules: {
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Correo inválido.'
          },
          }
  }),
  computed:{
    btn_text: function() {
      return this.opt1?'Crear Usuario':'Actualizar'
    },
    perfedit: function () {
      if (this.opt1) {
        return false
      } else {
        if (this.optedit){
          return true
        } else {
          return false
        }
        
      }
    },
    val_pass: function() {
      if (this.contraseña!=''&&this.contraseña === this.contraseña1) {
        return 'true'
      } else {
        if (this.contraseña=='') {
          return 'alert1'
          // return alert('Por favor verifique el campo contraseña, no puede estar vacio.')
        } else if (this.contraseña !== this.contraseña1) {
          return 'alert2'
          // return alert('Por favor verifique el campo contraseña, las contraseñas no coinciden.')
        } else {
          return alert('Por favor verifique el campo contraseña')
        }
      }
    }
  },
  methods: {
    open: function (id) {
      if (id) {
        this.opt1= false
        this.id = id
        AdminDataService.getUser(id)
          .then((response) => {
            this.id__ = response.data.id
            this.nombre = response.data.nombre
            this.username = response.data.username
            this.email = response.data.email
            this.planta = response.data.planta
            this.perfil = response.data.roles
          })
        this.optedit = true
        this.dialog = true
      } else {
        this.id=''
        this.opt1=true
        this.dialog = true
        this.nombre = undefined
        this.username = undefined
        this.email = undefined
        this.planta = []
        this.perfil = []
        this.optedit = false
        this.contraseña= undefined
        this.contraseña1= undefined
      }
    },
    btn_act() {
      // decide si debe crear crear usuario nuevo (opt=true), o actualizar usuario existente
      if (this.opt1) {
        // verifica que la contraseña coincida y envia datos para crear usuario nuevo
        if (this.val_pass=='true') {
          let data = {}
          data['username']= this.username
          data['nombre']= this.nombre
          data['email']= this.email
          data['password']= this.contraseña
          data['planta']= this.planta
          data['roles']= this.perfil
          AdminDataService.newUser(data)
          .then((response)=>{
            console.log(response.data)
            this.dialog=false
          })
        } else if (this.val_pass=='alert1') {
          alert('Por favor verifique el campo contraseña, no puede estar vacio.')
        } else if (this.val_pass=='alert2') {
          alert('Por favor verifique el campo contraseña, las contraseñas no coinciden.')
        }
      } else {
        // console.log('actualizar')
        if (this.val_pass=='true'){
        let data = {}
          data['id'] = this.id__
          data['username']= this.username
          data['nombre']= this.nombre
          data['email']= this.email
          data['password']= this.contraseña
          data['planta']= this.planta
          data['roles']= this.perfil
          AdminDataService.updateUser(data)
          .then((response)=>{
            console.log(response.data)
            this.dialog=false
          })
        } else if (this.val_pass=='alert1') {
          alert('Por favor verifique el campo contraseña, no puede estar vacio.')
        } else if (this.val_pass=='alert2') {
          alert('Por favor verifique el campo contraseña, las contraseñas no coinciden.')
        }
      }
    },
    btn_edit () {
      this.optedit=false
    },
    btn_del() {
      let data ={}
      data['username']=this.username
      AdminDataService.deleteUser(data)
      .then((response) =>{
        console.log(response.data)
      })
    }
  },
  mounted () {
    this.id=1
  }

}
</script>