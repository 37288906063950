import http from "../http-common";
import authHeader from './Auth-header';

class AdminDataService {
  getUsers() {
    return http.get('/users/', {headers : authHeader()})
  }
  getUser(id) {
    return http.get(`/user/${id}`, {headers: authHeader()});
  }
  newUser(data) {
    return http.post('/auth/signup/', data, {headers: authHeader()})
  }
  deleteUser (data) {
    return http.post('/user/delete/', data, {headers: authHeader()})
  }
  updateUser (data) {
    return http.post('/user/update', data, { headers: authHeader() })
  }
}

export default new AdminDataService();