<template>
  <v-container>
    <v-tabs>
      <v-tab>Usuarios</v-tab>
      <v-tab-item>
        <v-col>
          <v-btn @click="nwUsers()">
            <v-icon left>mdi-account-plus-outline</v-icon>usuario
          </v-btn>
          
          <v-data-table :items="users" :headers="headers" item-key="id">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-icon class="mr-2" @click="editUser(item.USERNAME)">
                    mdi-account-edit-outline
                  </v-icon>
                </td>
                <td>{{item.NOMBRE}}</td>
                <td>{{item.USERNAME}}</td>
                <!-- <td>
                  <v-select v-model="item.PLANTA" multiple readonly :items='item.PLANTA' />
                </td> -->
                <td>
                  <v-select v-model="item.PERFIL" multiple readonly :items='item.PERFIL' />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-tab-item>
      <v-tab>Ajustes notificacion areas</v-tab>
      <v-tab-item>
        <v-col>
                   
          <v-data-table :items="areas" :headers="areaHeaders" item-key="id">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-icon class="mr-2">
                    mdi-account-edit-outline
                  </v-icon>
                </td>
                <td>{{item.AREA}}</td>
                <!-- <td>{{item.MAIL}}</td> -->
                <td>
                  <v-text-field v-model="item.MAIL" :readonly="!item.EDIT"  > 
                    <template v-slot:append >
                      <v-icon v-if="item.EDIT" @click="saveMail(item.id, item.MAIL, 'area')">mdi-content-save-outline</v-icon>
                    </template>
                  </v-text-field>
                </td>
                <td>
                  <v-checkbox v-model="item.EDIT" :value="item.EDIT" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-tab-item>
      <v-tab>Ajustes notificacion general</v-tab>
      <v-tab-item>
        <v-col>
                   
          <v-data-table :items="nots" :headers="notsHeaders" item-key="id">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-icon class="mr-2">
                    mdi-account-edit-outline
                  </v-icon>
                </td>
                <td>{{item.AREA}}</td>
                <!-- <td>{{item.MAIL}}</td> -->
                <td>
                  <v-text-field v-model="item.MAIL" :readonly="!item.EDIT"  > 
                    <template v-slot:append >
                      <v-icon v-if="item.EDIT" @click="saveMail(item.id, item.MAIL, 'nots')">mdi-content-save-outline</v-icon>
                    </template>
                  </v-text-field>
                </td>
                <td>
                  <v-checkbox v-model="item.EDIT" :value="item.EDIT" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-tab-item>
    </v-tabs>
  <user-dialog ref="nwuser" />
  </v-container>
</template>

<script>
import AdminDataService from "../../services/AdminDataService";
import botasService from '../../services/botasService'
import UserDlg from './User.vue'
export default {
 name: "clasif",
 components: {
    userDialog: UserDlg,
  },
  data: () => ({
    users:[],
    headers: [
      { text: "", value: "actions", align:"center", sortable: false },
      { text: "Nombre", value: "NOMBRE", align: "start", sortable: false},
      { text: "Usuario", value: "USERNAME", align: "start", sortable: false},
      // { text: "Planta", value: "PLANTA", align: "start", sortable: false},
      { text: "Perfil", value: "PERFIL", align: "start", sortable: false},
    ],
    areas:[],
    areaHeaders:[
      { text: "", value: "actions", align:"center", sortable: false },
      { text: "Area", value: "AREA", align: "start", sortable: false},
      { text: "Mails", value: "MAIL", align: "start", sortable: false},
      { text: "Editar", value: "EDIT", align: "start", sortable: false}
    ],
    nots:[],
    notsHeaders:[
      { text: "", value: "actions", align:"center", sortable: false },
      { text: "Area", value: "AREA", align: "start", sortable: false},
      { text: "Mails", value: "MAIL", align: "start", sortable: false},
      { text: "Editar", value: "EDIT", align: "start", sortable: false}
    ]
  }),
  methods:{
    getUsers () {
      AdminDataService.getUsers()
       .then((response) =>{
         this.users=response.data.map(this.getUser)
       })
    },
    getUser(users) {
      return {
        id: users.id,
        NOMBRE: users.nombre,
        PLANTA: users.planta,
        USERNAME: users.username,
        PERFIL: users.roles.map(rol=>rol.name),
        }
    },
    getAreas() {
      let data = {}
      data['type_mail']='area_not'
      botasService.getMails(data)
      .then((resp) => {
        this.areas= resp.data.map(this.getArea)
      })
    },
    getArea(Areas) {
      return {
        id: Areas.id,
        AREA: Areas.AREA,
        MAIL: Areas.MAIL,
        EDIT: false
      }
    },getNots() {
      let data = {}
      data['type_mail']='gen_not'
      botasService.getMails(data)
      .then((resp) => {
        this.nots= resp.data.map(this.getArea)
      })
    },
    getnot(Nots) {
      return {
        id: Nots.id,
        AREA: Nots.AREA,
        MAIL: Nots.MAIL,
        EDIT: false
      }
    },
    saveMail(id, mail, type) {
      // console.log(id, mail);
      let data = {}
      data['id'] = id
      data['nwmail'] = mail
      botasService.updMail(data)
        .then((response) => {
          console.log(response.data);
          if (type === "area") {
            for (let i = 0; i < this.areas.length; i++) {
              if (this.areas[i].id == id) {
                this.areas[i].EDIT = false
              }
            }
          } else if (type === 'nots') {
            for (let i = 0; i < this.nots.length; i++) {
              if (this.nots[i].id == id) {
                this.nots[i].EDIT = false
              }
            }
          }
        })
    },
    async editUser(id) {
      this.id=id
      await this.$refs.nwuser.open(id)
      this.getUsers()

    },
    async nwUsers() {
      await this.$refs.nwuser.open()
      this.getUsers()
    }

  },
  mounted(){
    this.getUsers()
    this.getAreas()
    this.getNots()
  }
}
</script>